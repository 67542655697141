import React from 'react';
import moment from 'moment-timezone';
export const timeZone = process.env.GATSBY_TIMEZONE || 'Europe/London';

/**
* date formats
*/
// define common date formats here
export const dateFormats = {
    date: "DD/MM/yyyy",
    date1: "Do MMM YYYY",
    date2: "DD MMM YYYY",
    time: "hh:mma",
    timeOnly: "HH:mm",
    datetime: "DD/mm/yyyy - HH:mm",
    timestamp: "Do MM YYYY  hh:mma",
    calendarDate: "yyyy-MM-DD",
    dateFormat: 'YYYY-MM-DD',
    datePicker: 'yyyy-MM-DD HH:mm',
}

export const DateWithTime = ({timestamp}) => {

    if (!timestamp) {
        return false
    }

    let date = moment(timestamp).tz(timeZone).format(dateFormats.date);
    let time = moment(timestamp).tz(timeZone).format(dateFormats.time);

    return (
        <>
            {date} &middot; {time}
        </>
    )
}

export const ViewingDate = ({timestamp}) => {
    if (!timestamp)
        return <></>
    let date = moment(timestamp).tz(timeZone).format(dateFormats.date1);
    let time = moment(timestamp).tz(timeZone).format(dateFormats.time);

    return (
        <>
            {date} at {time}
        </>
    )
}

export const viewingDate = (timestamp) => {
    if (!timestamp)
        return '';
    let date = moment(timestamp).tz(timeZone).format(dateFormats.date1);
    let time = moment(timestamp).tz(timeZone).format(dateFormats.time);

    return `${date} at ${time}`;
}

export const displayData = (timestamp, format='date1') => {
    if (!timestamp)
        return false;
    if (timestamp.length <= 10)
        return moment(timestamp).format(dateFormats[format]);
    return moment(timestamp).tz(timeZone).format(dateFormats[format]);
}

export const newsDate = (timestamp) => {
    return displayData(timestamp, 'date2')
}

export const calendarDate = (timestamp) => {
    if (!timestamp)
        return false;
    return moment(timestamp).tz(timeZone).format(dateFormats.calendarDate);
}

export const getMonth = (timestamp) => {
    if (!timestamp)
        return false;
    return moment(timestamp).format('MMMM');
}

export const dateRange = (timestamp) => {
    const startOfMonth = moment(timestamp).startOf('month').format('YYYY-MM-DD');
    const endOfMonth   = moment(timestamp).endOf('month').format('YYYY-MM-DD');

    return {
        actionFrom: startOfMonth,
        actionTo: endOfMonth
    }
}

export const timeFromNow = (timestamp) => {
    if (!timestamp)
        return false;
    return moment(timestamp).tz(timeZone).fromNow();
}

export const getTime = (timestamp) => {
    if (!timestamp)
        return false;
    return moment(timestamp).format(dateFormats.timeOnly);
}

export const getDateFormat = (timestamp) => {
    if (!timestamp)
        return false;
    return moment(timestamp).format(dateFormats.dateFormat);
}

export const datePickerFormat = (timestamp) => {
    if (!timestamp)
        return false;
    return moment(timestamp).format(dateFormats.datePicker);
}

export function subtractDate(date, dateToLess, format='YYYY-MM-DD') {
    if (!date)
        return false;
    return moment(new Date()).subtract(dateToLess,'d').format(format)
}

export const dateFieldFormat = (timestamp) => {
    if (!timestamp)
        return false;
    return moment(timestamp).tz(timeZone).format(dateFormats.dateFormat);
}

export const strToFullTime = (time) => {
    const [hours, minutes] = time.split(':');
    const date = new Date(+'1995', '1', +'1', +hours, +minutes, +'0');
    return getTime(date)
}

// Probably better to not export a default, as there are many choices, and
// this file isn't called DateWithTime.jsx
//export default DateWithTime;
